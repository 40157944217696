<template>
    <svg width="100" height="50" viewBox="0 0 565 169" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M269.181 167.1C185.363 167.1 102.046 166.985 18.7299 167.26C9.31786 167.291 -0.0782594 157.925 0.000491589 148.577C0.366873 105.089 0.321143 61.5944 0.0261986 18.1049C-0.0312964 9.62708 8.97124 -0.00462933 18.2668 1.66931e-06C193.899 0.0875257 369.532 0.0603467 545.164 0.0765357C554.038 0.0773557 563.133 9.05322 563.153 18.2277C563.253 62.0522 563.124 105.877 563.267 149.702C563.293 157.406 553.125 167.318 544.126 167.287C452.645 166.981 361.163 167.1 269.181 167.1ZM277.681 163.221C365.16 163.221 452.64 163.05 540.118 163.403C553.313 163.456 559.2 154.475 559.124 144.338C558.82 103.85 559.003 63.3586 558.996 22.8682C558.994 10.1732 552.979 4.09779 540.45 4.09773C367.657 4.09693 194.865 4.09692 22.0732 4.09777C9.93766 4.09783 3.80592 9.99649 3.78889 22.0528C3.73098 63.043 3.73871 104.033 3.78351 145.023C3.79715 157.509 9.68366 163.22 22.2412 163.22C107.054 163.221 191.867 163.221 277.681 163.221Z" fill="#A0A0A0"/>
<rect x="4" y="4" width="555.38" height="159.31" rx="15" fill="#010101" class="playStroreIcon" />
<g clip-path="url(#clip0_228_353)"> 
<path d="M80.6404 65.9189C87.9545 65.9189 94.052 69.678 96.8916 73.7404V66.9337H108.063V117.716H100.476C98.4966 117.716 96.8916 116.114 96.8916 114.133V110.916C94.052 114.975 87.9545 118.732 80.6404 118.732C66.7304 118.732 56.1641 106.851 56.1641 92.3267C56.1641 77.8022 66.7304 65.9189 80.6404 65.9189ZM82.6727 76.0771C73.6319 76.0771 67.5372 83.1849 67.5372 92.3267C67.5372 101.466 73.6319 108.578 82.6727 108.578C91.7108 108.578 97.8083 101.466 97.8083 92.3267C97.8083 83.1849 91.7113 76.0771 82.6727 76.0771Z" fill="white"/>
<path d="M120.75 66.9353H131.924V73.8412C134.968 68.7647 140.76 65.9205 147.362 65.9205C159.142 65.9205 166.762 74.2488 166.762 87.1485V117.716H159.172C157.193 117.716 155.587 116.111 155.587 114.132V88.9768C155.587 80.9541 151.523 76.0787 145.027 76.0787C137.612 76.0787 131.925 81.8679 131.925 92.733V117.716H124.332C122.356 117.716 120.75 116.111 120.75 114.132V66.9353V66.9353Z" fill="white"/>
<path d="M200.076 65.9189C207.388 65.9189 213.485 69.678 216.327 73.7404V41.5452H227.496V117.716H219.909C217.933 117.716 216.327 116.114 216.327 114.132V110.916C213.485 114.975 207.388 118.732 200.076 118.732C186.16 118.732 175.598 106.851 175.598 92.3266C175.598 77.8022 186.161 65.9189 200.076 65.9189ZM202.109 76.0771C193.071 76.0771 186.976 83.1849 186.976 92.3266C186.976 101.466 193.071 108.578 202.109 108.578C211.144 108.578 217.238 101.466 217.238 92.3266C217.238 83.1849 211.144 76.0771 202.109 76.0771Z" fill="white"/>
<path d="M240.188 66.9353H251.359V75.9745C253.287 70.1853 258.976 66.4279 264.767 66.4279C266.185 66.4279 267.612 66.5306 269.135 66.8344V78.3101C267.2 77.7001 265.58 77.3963 263.551 77.3963C257.151 77.3963 251.359 82.7818 251.359 92.9377V117.716H243.77C241.794 117.716 240.188 116.111 240.188 114.132V66.9353H240.188Z" fill="white"/>
<path d="M299.257 118.732C284.229 118.732 272.648 107.05 272.648 92.3295C272.648 77.6021 284.229 65.9189 299.257 65.9189C314.292 65.9189 325.867 77.5993 325.867 92.3295C325.867 107.05 314.292 118.732 299.257 118.732ZM299.257 108.376C308.195 108.376 314.494 101.365 314.494 92.3295C314.494 83.292 308.195 76.2806 299.257 76.2806C290.22 76.2806 283.923 83.292 283.923 92.3295C283.923 101.365 290.22 108.376 299.257 108.376Z" fill="white"/>
<path d="M340.508 55.8661C336.446 55.8661 332.992 52.518 332.992 48.349C332.992 44.2867 336.446 40.9357 340.508 40.9357C344.671 40.9357 347.923 44.2872 347.923 48.349C347.923 52.518 344.671 55.8661 340.508 55.8661ZM334.926 66.9336H346.095V117.716H338.508C336.529 117.716 334.926 116.108 334.926 114.132V66.9336Z" fill="white"/>
<path d="M379.75 65.9189C387.064 65.9189 393.159 69.678 396.004 73.7404V41.5452H407.176V117.716H399.589C397.606 117.716 396.004 116.114 396.004 114.132V110.916C393.159 114.975 387.064 118.732 379.75 118.732C365.84 118.732 355.277 106.851 355.277 92.3266C355.277 77.8022 365.84 65.9189 379.75 65.9189ZM381.783 76.0771C372.749 76.0771 366.654 83.1849 366.654 92.3266C366.654 101.466 372.749 108.578 381.783 108.578C390.824 108.578 396.919 101.466 396.919 92.3266C396.918 83.1849 390.824 76.0771 381.783 76.0771Z" fill="white"/>
<path d="M426.599 73.3947H415.668V66.6562H426.599V52.4571H433.747V66.6562H448.66V73.3947H433.747V102.913C433.747 109.142 436.507 111.592 442.224 111.592C444.265 111.592 447.025 111.183 448.66 110.471V116.904C446.307 117.824 443.859 118.233 441.1 118.233C432.42 118.233 426.599 113.224 426.599 103.522V73.3947V73.3947Z" fill="white"/>
<path d="M506.774 66.6546L482.877 117.722H477.26L453.359 66.6546H461.326L480.017 107.201L498.707 66.6546H506.774Z" fill="white"/>
</g>
<defs>
<clipPath id="clip0_228_353">
<rect width="451" height="77.7975" fill="white" transform="translate(56 41)"/>
</clipPath>
</defs>
</svg>

</template>
<script>

export default{
    name:"andriodtvicon"
}
</script>
<style lang="scss">
  @import "@/sass/_variables.scss";
  @import "@/sass/_components.scss";

  .playStroreIcon{
 fill: $clr-playstoreicon-mode;
}
</style>