<template>
    <div>
        <svg  width="100" height="50" class="firetv" viewBox="0 0 565 169" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M269.181 167.1C185.363 167.1 102.046 166.985 18.7299 167.26C9.31786 167.291 -0.0782594 157.925 0.000491589 148.577C0.366873 105.089 0.321143 61.5944 0.0261986 18.1049C-0.0312964 9.62708 8.97124 -0.00462933 18.2668 1.66931e-06C193.899 0.0875257 369.532 0.0603467 545.164 0.0765357C554.038 0.0773557 563.133 9.05322 563.153 18.2277C563.253 62.0522 563.124 105.877 563.267 149.702C563.293 157.406 553.125 167.318 544.126 167.287C452.645 166.981 361.163 167.1 269.181 167.1ZM277.681 163.221C365.16 163.221 452.64 163.05 540.118 163.403C553.313 163.456 559.2 154.475 559.124 144.338C558.82 103.85 559.003 63.3586 558.996 22.8682C558.994 10.1732 552.979 4.09779 540.45 4.09773C367.657 4.09693 194.865 4.09692 22.0732 4.09777C9.93766 4.09783 3.80592 9.99649 3.78889 22.0528C3.73098 63.043 3.73871 104.033 3.78351 145.023C3.79715 157.509 9.68366 163.22 22.2412 163.22C107.054 163.221 191.867 163.221 277.681 163.221Z" fill="#A0A0A0"/>
<rect x="4" y="4" width="555.38" height="159.31" rx="15" fill="#2A2B75" class="playStroreIcon" />
<path fill-rule="evenodd" clip-rule="evenodd" d="M327.67 109.702C314.882 119.142 296.348 124.149 280.393 124.149C258.03 124.149 237.883 115.885 222.637 102.117C221.445 101.031 222.501 99.553 223.949 100.398C240.401 109.973 260.729 115.734 281.736 115.734C295.911 115.734 311.474 112.793 325.815 106.716C327.971 105.796 329.796 108.134 327.67 109.702Z" fill="#FC4C02"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M332.984 103.625C331.355 101.529 322.171 102.629 318.054 103.127C316.803 103.278 316.607 102.192 317.738 101.393C325.051 96.2505 337.04 97.7435 338.443 99.4626C339.845 101.197 338.066 113.216 331.219 118.961C330.164 119.836 329.153 119.368 329.636 118.207C331.174 114.347 334.627 105.721 332.999 103.625H332.984Z" fill="#FC4C02"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M280.837 62.8783C288.03 62.8783 287.97 69.1818 287.804 71.8962H272.588C272.859 67.1761 275.136 62.8783 280.837 62.8783V62.8783ZM292.373 76.0734C293.293 76.0734 293.413 73.0574 293.444 71.7756C293.79 62.6219 288.95 58.1733 281.093 58.1733C276.75 58.1733 272.754 59.7416 270.25 62.7727C267.928 65.5324 266.586 69.4532 266.586 74.7312C266.586 80.0093 267.762 83.7944 270.024 86.539C272.347 89.2986 275.875 91.2289 282.013 91.2289C284.335 91.2289 288.844 90.7312 291.679 89.3288C292.72 88.9367 292.735 88.4692 292.735 87.489V85.785C292.735 84.8651 292.343 84.4881 291.332 84.8047C288.075 85.9056 284.803 86.3429 282.827 86.3429C279.434 86.3429 276.448 85.3627 274.82 83.4174C273.206 81.3062 272.663 78.9537 272.527 76.0885H292.373V76.0734Z" fill="white"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M218.883 59.1082V53.046C218.883 48.2053 223.739 48.8236 226.83 49.2157C228.203 49.4871 228.73 49.2609 228.73 48.1752V46.1695C228.73 45.1742 228.323 44.8123 226.966 44.4504C219.366 43.0479 212.746 44.5559 212.746 54.2223V59.2741L208.297 59.7868C207.347 59.8773 207 60.1638 207 61.1139V62.7124C207 63.587 207.377 63.9339 208.206 63.9339H212.746V89.0422C212.746 89.9018 213.092 90.2486 213.967 90.2486H217.677C218.551 90.2486 218.898 89.9018 218.898 89.0422V63.9339H227.132C227.991 63.9339 228.338 63.587 228.338 62.7124V60.3297C228.338 59.4551 227.946 59.1082 227.132 59.1082H218.898H218.883Z" fill="white"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M238.457 59.1535H234.808C233.933 59.1535 233.586 59.5003 233.586 60.3599V89.0422C233.586 89.9018 233.933 90.2486 234.808 90.2486H238.457C239.332 90.2486 239.678 89.9018 239.678 89.0422V60.3599C239.678 59.6662 239.332 59.1535 238.457 59.1535V59.1535ZM236.617 46.1997C234.476 46.1997 232.832 47.6323 232.832 49.9245C232.832 52.2167 234.325 53.6493 236.662 53.6493C239 53.6493 240.493 52.2167 240.493 49.9245C240.493 47.391 238.894 46.1997 236.602 46.1997H236.617Z" fill="white"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M343.186 90.2486H339.295C338.42 90.2486 337.938 89.9017 337.591 89.0422L326.311 60.5861C325.964 59.7265 326.19 59.1534 327.095 59.1534H331.257C332.087 59.1534 332.433 59.6662 332.705 60.3598L341.24 84.6237L349.715 60.3598C350.092 59.5003 350.469 59.1534 351.344 59.1534H355.069C356.109 59.1534 356.35 59.6963 355.958 60.5559L344.663 89.0422C344.362 89.7359 344 90.2486 343.155 90.2486H343.186Z" fill="#FC4C02"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M307.539 82.2712C307.539 89.5097 312.531 90.701 316.135 90.701C318.08 90.701 319.648 90.505 321.202 90.1732C323.102 89.6152 323.087 89.2985 323.087 88.4239V86.2222C323.087 85.3476 322.664 85.0761 321.85 85.2722C320.387 85.5134 319.422 85.6793 317.974 85.7396C313.963 85.4531 313.601 84.1713 313.601 80.2806V63.9338H321.654C322.514 63.9338 322.86 63.587 322.86 62.7123V60.2845C322.86 59.4098 322.468 59.063 321.654 59.063H313.601V51.5832C313.601 50.8896 313.254 50.3768 312.38 50.3768H309.937C308.987 50.3768 308.519 50.7237 308.429 51.6737L307.66 59.2138L303.105 59.7717C302.155 59.8622 301.809 60.3297 301.809 61.2797V62.6973C301.809 63.5719 302.321 63.9188 303.015 63.9188H307.509V82.2712H307.539Z" fill="#FC4C02"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M253.49 63.6323L253.038 60.1337C252.842 59.2741 252.344 59.1535 251.635 59.1535H248.906C248.046 59.1535 247.699 59.5003 247.699 60.3599V89.0422C247.699 89.9018 248.046 90.2486 248.906 90.2486H252.54C253.399 90.2486 253.746 89.9018 253.746 89.0422V67.3118C256.114 65.5776 258.15 64.3863 262.809 64.3863C263.94 64.4014 265.554 64.8387 265.554 63.6172V60.0583C265.554 59.259 265.087 58.8217 263.548 58.8217C259.703 58.8217 257.139 59.7868 253.475 63.6172L253.49 63.6323Z" fill="white"/>
</svg>
    </div>
    
</template>
<script>

export default{
    name:"firetvicon"
}

</script>

<style lang="scss">
  @import "@/sass/_variables.scss";
  @import "@/sass/_components.scss";

.playStroreIcon{
//  stroke:;
 fill: $clr-playstoreicon-mode;
  padding: 5px;
}
// .firetv{
//     padding: 5px;
//     height: 50px;
// }
</style>